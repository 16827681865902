import React, { useState } from 'react';

type Props = {
  label: string;
  placeholder?: string;
  array: string[];
  setArray: (arr: string[]) => void;
};

export const ArrayTextInputField: React.FC<Props> = ({ label, placeholder, array, setArray }) => {
  const [newItem, setNewItem] = useState('');

  return (
    <div className="field">
      <label className="label">{label}</label>

      <div className="control">
        <input
          className="input"
          type="text"
          placeholder={placeholder || `Type ${label} and press Enter`}
          value={newItem}
          onChange={(e) => setNewItem(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setArray([...(array || []), newItem]);
              setNewItem('');
            }
          }}
        />
      </div>

      <div className="tags">
        {array &&
          array.map((item) => (
            <span key={item} className="tag is-medium">
              {item}
              <button
                type="button"
                className="delete is-small"
                onClick={() => setArray(array.filter((i) => i !== item))}
              />
            </span>
          ))}
      </div>
    </div>
  );
};
