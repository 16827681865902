import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../utils/fetch';
import { TeamInvite } from '../../types/TeamInvite';

type InvitingEmail = { invitingEmail: string };

export const Invites: React.FC = () => {
  const [invites, setInvites] = useState<(TeamInvite & InvitingEmail)[] | null>(null);

  const getInvitesAndAdminsFromApi = async () => {
    try {
      const invitesFromApi = await api.get<(TeamInvite & InvitingEmail)[]>('/team-invites');
      setInvites(invitesFromApi);
    } catch (err) {
      console.error(err);
    }
  };

  const expiresAtColor = (d: string) => {
    const date = new Date(d);
    const now = new Date();

    if (date < now) {
      return 'red';
    }

    return 'darkgreen';
  };

  useEffect(() => {
    getInvitesAndAdminsFromApi();
  }, []);

  return (
    <div className="box">
      <p className="title">Invites: {invites?.length || 0}</p>
      <div className="table-container">
        <table className="table is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th>Email</th>
              <th>Invite URL</th>
              <th>Created At</th>
              <th>Expires At</th>
              <th>Inviting user</th>
              <th>Inviting team</th>
            </tr>
          </thead>
          <tbody>
            {invites &&
              invites.map((invite: TeamInvite & InvitingEmail) => (
                <tr key={invite.token}>
                  <td>
                    <strong>{invite.email}</strong>
                  </td>
                  <td>
                    <a href={`https://app.partner-way.com/invitation?token=${invite.token}`} target="_blank">
                      Visit URL
                    </a>
                  </td>
                  <td>{new Date(invite.createdAt).toLocaleDateString()}</td>
                  <td>
                    <span style={{ color: expiresAtColor(invite.expiresAt) }}>
                      {new Date(invite.expiresAt).toLocaleDateString()}
                    </span>
                  </td>
                  <td>
                    <Link to={`/users/${invite.createdBy}`}>{invite.invitingEmail}</Link>
                  </td>
                  <td>
                    <Link to={`/teams/${invite.teamId}`}>View team</Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
