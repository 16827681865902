import React from 'react';
import { Link } from 'react-router-dom';
import { ProductsPricesTable } from '../../components/ProductsPricesTable';

export const ProductsPricesPage: React.FC = () => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <h1 className="title">Products prices</h1>

        <div className="is-flex" style={{ gap: '12px' }}>
          <Link to="/products-prices/from-csv" className="button is-warning">
            PriceList from Google CSV
          </Link>

          <Link to="/products-prices/new" className="button is-primary">
            Create product price
          </Link>
        </div>
      </div>

      <ProductsPricesTable />
    </>
  );
};
