import React from 'react';

type Transaction = {
  id: string;
  amount: number;
  currency: string;
  sourceId: string;
  createdAt: string;
  description: string;
};

type Props = {
  transactions: Transaction[];
};

export const TransactionsTable: React.FC<Props> = ({ transactions }) => {
  return (
    <>
      <table className="table is-bordered is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th>
              <abbr title="Date">Transaction date</abbr>
            </th>
            <th>
              <abbr title="Amount">Amount</abbr>
            </th>
            <th>
              <abbr title="Currency">Currency</abbr>
            </th>
            <th>
              <abbr title="Description">Description</abbr>
            </th>
            <th>
              <abbr title="source">Source</abbr>
            </th>
          </tr>
        </thead>

        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.createdAt}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.currency}</td>
              <td>{transaction.description || ''}</td>
              <td>{transaction.sourceId || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
