import React from 'react';
import { useParams } from 'react-router-dom';
import { CostForm } from '../../components/CostForm';

export const CreateCostPage: React.FC = () => {
  const { costId } = useParams();
  const title = costId === 'new' ? 'Create cost' : 'Edit cost';

  return (
    <div>
      <h1 className="title">{title}</h1>

      <CostForm />
    </div>
  );
};
