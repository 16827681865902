import React from 'react';
import { useParams } from 'react-router-dom';
import { TeamForm } from '../../components/TeamForm';

export const TeamPage: React.FC = () => {
  const { teamId } = useParams();
  const title = teamId === 'new' ? 'Create team' : 'Edit team';

  return (
    <div className="">
      <h1 className="title">{title}</h1>

      <TeamForm />
    </div>
  );
};
