import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { AdminRoles, User } from '../../../../types/User';
import { ArrayTextInputField } from '../../../ArrayTextInputField';
import { TextInputField } from '../../../TextInputField';
import { DeletePrompt } from '../../../DeletePrompt';
import avatarPlaceholder from '../../../../media/avatar-placeholder.svg';
import { Team } from '../../../../types/Team';
import { api } from '../../../../utils/fetch';

const roles = {
  user: ['user'],
  moderator: ['user', 'moderator'],
  admin: ['user', 'moderator', 'admin'],
  superadmin: ['user', 'moderator', 'admin', 'superadmin'],
};

type Props = {
  user: Omit<User, 'balance'>;
  teams: Team[] | null;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
  setDomains: (domains: string[]) => void;
};

export const UserInfoTab: React.FC<Props> = ({ user, teams, handleChange, setDomains }) => {
  const { firstName, lastName, email, picture, phoneNumber, domains, role, reseller, teamId, adminRole } = user;

  const [currentAdminRole, setCurrentAdminRole] = useState<AdminRoles>('user');
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    async function getAdminFromApi() {
      try {
        const user = await api.get<User>('/auth/me');

        setCurrentAdminRole(user.adminRole);
      } catch (e) {}
    }

    getAdminFromApi();
  }, []);

  const isAdminRoleEnoughToEdit = roles.superadmin.indexOf(currentAdminRole) >= roles.superadmin.indexOf(adminRole);

  const resetHandler = async () => {
    try {
      await api.get('/users/reset/' + user.id);

      setIsReset(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="box">
      {isReset && (
        <DeletePrompt
          title="Are you sure? Reset the password?"
          buttons={
            <>
              <button type="button" className="button is-warning" onClick={resetHandler}>
                Reset
              </button>
              <button type="button" className="button is-link" onClick={() => setIsReset(false)}>
                Close
              </button>
            </>
          }
        />
      )}

      <p className="title is-4 has-text-info">User information</p>

      <label className="label">Picture URL:</label>
      <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }} className="field has-addons">
        <div className="control">
          <figure className="image is-64x64">
            <img className="is-rounded" src={picture || avatarPlaceholder} height="70" width="70" alt="avatar" />
          </figure>
        </div>
        <div className="control is-expanded">
          <TextInputField
            name="picture"
            value={picture || ''}
            placeholder="Picture URL input"
            onChange={handleChange}
          />
        </div>
      </div>

      <TextInputField
        disabled
        name="email"
        label="Email:"
        value={email || ''}
        placeholder="Email input"
        onChange={handleChange}
      />

      <TextInputField
        name="firstName"
        label="First name:"
        value={firstName || ''}
        placeholder="First name input"
        onChange={handleChange}
      />

      <TextInputField
        name="lastName"
        label="Last name:"
        value={lastName || ''}
        placeholder="Last name input"
        onChange={handleChange}
      />

      {isAdminRoleEnoughToEdit && (
        <div className="field">
          <label className="label">Admin Role</label>
          <div className="control">
            <div className="select">
              <select name="adminRole" value={adminRole} onChange={handleChange}>
                {roles[currentAdminRole].map((role: string) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      <TextInputField
        name="phoneNumber"
        label="Phone number:"
        value={phoneNumber || ''}
        placeholder="Phone number input"
        onChange={handleChange}
      />

      <TextInputField name="role" label="Role:" value={role || ''} placeholder="Role input" onChange={handleChange} />

      {currentAdminRole === 'superadmin' && (
        <button type="button" onClick={() => setIsReset(true)} className="button is-danger">
          Reset Password
        </button>
      )}
    </div>
  );
};
