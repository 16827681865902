import React from 'react';
import { GooglePricelistForm } from '../../components/GooglePricelistForm';

export const ProductsPricesFromGoogleCsv: React.FC = () => {
  return (
    <>
      <h1 className="title">Products prices from Google pricelist.csv</h1>

      <GooglePricelistForm />
    </>
  );
};
