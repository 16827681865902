import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeletePrompt } from '../../components/DeletePrompt';
import { SearchTextField } from '../../components/SearchTextField';
import { TeamsTable } from '../../components/TeamsTable';
import { Team } from '../../types/Team';
import { api } from '../../utils/fetch';

export const TeamsPage: React.FC = () => {
  const [teams, setTeams] = useState<null | Team[]>(null);
  const [idToDel, setIdToDel] = useState<string | null>(null);
  const [searchBy, setSearchBy] = useState('');

  const handleDeleteClick = async () => {
    try {
      await api.delete(`/teams/${idToDel}`);
      await getTeamsFromAPI();
      setIdToDel(null);
      setSearchBy('');
    } catch (err) {
      console.error(err);
    }
  };

  const getTeamsFromAPI = async () => {
    try {
      const res = await api.get<Team[]>('/teams');

      setTeams(res);
    } catch (err) {
      console.error(err);
    }
  };

  const filteredTeams = teams?.filter(
    (team) => team.name.toLowerCase().match(searchBy.toLowerCase()) || team.domain?.match(searchBy)
  );

  useEffect(() => {
    getTeamsFromAPI();
  }, []);

  return (
    <div className="">
      <div className="is-flex is-justify-content-space-between">
        <h1 className="title">Teams</h1>

        <Link to="/teams/new" className="button is-info">
          Create team
        </Link>
      </div>

      <SearchTextField
        searchBy={searchBy}
        onChangeSeachBy={(str) => setSearchBy(str)}
        onResetSearchBy={() => setSearchBy('')}
      />

      {idToDel && (
        <DeletePrompt
          title="Are you sure?"
          buttons={
            <>
              <button onClick={handleDeleteClick} className="button mr-3 is-danger">
                Delete
              </button>

              <button onClick={() => setIdToDel(null)} className="button is-link">
                Close
              </button>
            </>
          }
        />
      )}

      {filteredTeams && <TeamsTable teams={filteredTeams} onDeleteClick={(id) => setIdToDel(id)} />}
    </div>
  );
};
