import classNames from 'classnames';
import React from 'react';
import { Card } from '../../../../types/Card';

type Props = {
  cards?: Card[];
  activeCard: string;
  onChangeActiveCard: (id: string) => void;
};

export const CardsTab: React.FC<Props> = ({ cards, activeCard, onChangeActiveCard }) => {
  return (
    <div className="box">
      <p className="title is-4 has-text-info">Team's cards</p>

      {cards &&
        cards.length > 0 &&
        cards.map((card) => (
          <div
            key={card.id}
            className="box"
            style={{ width: 'max-content', border: activeCard === card.id ? '1px solid green' : '' }}
          >
            <strong style={{ marginRight: '8px' }}>{card.number}</strong>
            <span
              className={classNames('tag', {
                'is-info': card.brand === 'VISA',
                'is-danger': card.brand !== 'VISA',
              })}
            >
              {card.brand}
            </span>
            <div>{card.bank}</div>
            <div>{card.cardHolder}</div>
            {card.id === activeCard ? (
              <span className="tag is-success">ACTIVE</span>
            ) : (
              <button
                style={{ marginRight: '8px' }}
                type="button"
                onClick={() => onChangeActiveCard(card.id)}
                className="button is-small is-link is-light"
              >
                Set as active
              </button>
            )}
          </div>
        ))}

      {(!cards || !cards.length) && <p className="title is-5 has-text-link">Team dont have any cards yet.</p>}
    </div>
  );
};
