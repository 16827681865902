import React, { ChangeEventHandler } from 'react';
import { ArrayTextInputField } from '../../../ArrayTextInputField';
import { TextInputField } from '../../../TextInputField';
import avatarPlaceholder from '../../../../media/avatar-placeholder.svg';
import { Team } from '../../../../types/Team';

type Props = {
  team: Team;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
  setDomains: (domains: string[]) => void;
};

export const TeamInfoTab: React.FC<Props> = ({ team, handleChange, setDomains }) => {
  const { name, picture, domains, reseller, users } = team;

  return (
    <div className="box">
      <p className="title is-4 has-text-info">Base team information</p>

      <label className="label">Picture URL:</label>
      <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }} className="field has-addons">
        <div className="control">
          <figure className="image is-64x64">
            <img className="is-rounded" src={picture || avatarPlaceholder} height="70" width="70" alt="avatar" />
          </figure>
        </div>
        <div className="control is-expanded">
          <TextInputField
            name="picture"
            value={picture || ''}
            placeholder="Picture URL input"
            onChange={handleChange}
          />
        </div>
      </div>

      <TextInputField
        name="name"
        label="Name:"
        value={name || ''}
        placeholder="Team name input"
        onChange={handleChange}
      />

      <div className="field">
        <label className="label">Reseller</label>
        <div className="control">
          <div className="select">
            <select name="reseller" value={reseller} onChange={handleChange}>
              <option value="reseller-ukraine">reseller-ukraine</option>
              <option value="reseller-estonia">reseller-estonia</option>
            </select>
          </div>
        </div>
      </div>

      <ArrayTextInputField
        label="Domains"
        placeholder="Type domain and press Enter"
        array={domains || []}
        setArray={setDomains}
      />

      <div className="box">
        Total users in {name} team: {users?.length}
      </div>
    </div>
  );
};
