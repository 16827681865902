import React from "react";

export const Footer: React.FC = () => {
  return (
    <footer className="footer mt-5">
      <div className="content has-text-centered">
        Client CMS
        <br />
        <a href="https://www.partner-way.com/">https://www.partner-way.com</a>
      </div>
    </footer>
  );
};
