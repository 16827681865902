import React from 'react';
import { Transaction } from '../../../../types/Transaction';
import { TransactionsTable } from '../../TransactionsTable';

type Props = {
  transactions?: Transaction[];
};

export const TransactionsTab: React.FC<Props> = ({ transactions }) => {
  return (
    <div className="box">
      <p className="title is-4 has-text-info">Team's transactions</p>

      {transactions && transactions.length ? (
        <TransactionsTable transactions={transactions} />
      ) : (
        <p className="title is-5 has-text-link">Team dont have any transactions yet.</p>
      )}
    </div>
  );
};
