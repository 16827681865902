import React, { ChangeEventHandler } from 'react';

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  type?: 'text' | 'password' | 'number';
};

export const TextInputField: React.FC<Props> = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  disabled = false,
  type = 'text',
}) => {
  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <input
          disabled={disabled}
          className="input"
          type={type}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
