import type { Dispatch, Reducer } from 'react';
import type { Reseller } from '../../types/Reseller';
import { api } from '../../utils/fetch';
import { tryCatch } from '../../utils/tryCatch';

export type State =
  | { mode: 'initial' }
  | { mode: 'ready'; resellers: Reseller[] }
  | { mode: 'loading' }
  | { mode: 'error'; error: string };

type Action =
  | { type: 'loadStart' }
  | { type: 'loadSuccess'; resellers: Reseller[] }
  | { type: 'loadError'; error: Error };

export const getInitialState = (): State => ({
  mode: 'initial',
});

export const reducer: Reducer<State, Action> = (state, action) => {
  if (action.type === 'loadStart') {
    return { mode: 'loading' };
  }
  if (action.type === 'loadSuccess') {
    return { mode: 'ready', resellers: action.resellers };
  }
  if (action.type === 'loadError') {
    return { mode: 'error', error: action.error.message };
  }
  return state;
};

type LoadResellersParams = {
  dispatch: Dispatch<Action>;
};

export const loadResellers = async (params: LoadResellersParams) => {
  const { dispatch } = params;
  dispatch({ type: 'loadStart' });
  const [error, newResellers] = await tryCatch(() => api.get<Reseller[]>('/resellers'));
  if (error) {
    return dispatch({ type: 'loadError', error });
  }
  dispatch({ type: 'loadSuccess', resellers: newResellers });
};
