import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { api } from '../../utils/fetch';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import { Loader } from '../Loader';
import { ErrorMessage } from '../ErrorMessage';
import { Cost } from '../../types/Cost';
import { SearchTextField } from '../SearchTextField';

export const CostsTable: React.FC = () => {
  const columns: ColumnsType<Cost> = [
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      render: (_, item) => (
        <Link className="button is-info" to={`/costs/${item.id}`}>
          Open
        </Link>
      ),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      render: (_, item) => <span className={item.clientCost ? '' : 'text-red'}>{item.domain}</span>,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (_, item) => <span className={item.clientCost ? '' : 'text-red'}>{item.product}</span>,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Client cost',
      dataIndex: 'clientCost',
      key: 'clientCost',
    },
    {
      title: 'Vat percent',
      dataIndex: 'vatPercent',
      key: 'vatPercent',
    },
    {
      title: 'Discount percent',
      dataIndex: 'discountPercent',
      key: 'discountPercent',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      render: (_, item) => (
        <button className="button is-warning" onClick={() => onDeleteHandler(item)}>
          {deletingCost?.id === item.id ? <Loader /> : 'Delete'}
        </button>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [deletingCost, setDeletingCost] = useState<Cost | null>(null);
  const [costs, setCosts] = useState<Cost[] | null>(null);
  const [searchBy, setSearchBy] = useState('');

  const filteredCosts = costs?.filter(
    (cost) =>
      cost.domain.toLowerCase().match(searchBy.toLowerCase()) ||
      cost.product.toLowerCase().match(searchBy.toLowerCase())
  );

  async function onDeleteHandler(costItem: Cost) {
    setDeletingCost(costItem);

    try {
      await api.delete('/costs/' + costItem.id);
      await getCostsFromAPI();
      setSearchBy('');
    } catch (e) {
      console.error(e);
      setIsError(true);
    }

    setDeletingCost(null);
  }

  const getCostsFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<Cost[]>('/costs');

      setCosts(response);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getCostsFromAPI();
  }, []);

  return (
    <>
      {isError && <ErrorMessage message={'Error while loading costs =('} />}

      {isLoading && <Loader />}

      <SearchTextField
        searchBy={searchBy}
        onChangeSeachBy={(str) => setSearchBy(str)}
        onResetSearchBy={() => setSearchBy('')}
      />

      {!isLoading && !isError && filteredCosts && <Table rowKey="id" dataSource={filteredCosts} columns={columns} />}
    </>
  );
};
