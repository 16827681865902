import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Reseller } from "../../types/Reseller";
import { api } from "../../utils/fetch";
import { ResellerForm } from "../../components/ResellerForm";
import { Loader } from "../../components/Loader";
import { ErrorMessage } from "../../components/ErrorMessage";

export const ResellerPage: React.FC = () => {
  const { resellerId } = useParams();

  const [reseller, setReseller] = useState<Reseller | null>(null);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getResellerFromAPI = async () => {
    setIsLoading(true);

    try {
      const response = await api.get<Reseller>('/resellers/' + resellerId);

      setReseller(response);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getResellerFromAPI();
  }, []);

  return (
    <>
      <h1 className="title">Reseller page</h1>

      {reseller &&
        <ResellerForm resellerItem={reseller} isNewReseller={false} />
      }

      {isLoading && <Loader />}

      {isError && <ErrorMessage message={'Error on reseller loading'} />}
    </>
  )
}
