import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserWithTeamName } from '../../types/User';
import avatarPlaceholder from '../../media/avatar-placeholder.svg';
import arrowDown from '../../media/chevron-down-outline.svg';

type Props = {
  users: UserWithTeamName[];
};

const adminColors = {
  user: '#ab5500',
  moderator: '#00aba5',
  admin: '#2b00ab',
  superadmin: '#a200c7',
};

export const UsersTable: React.FC<Props> = ({ users }) => {
  const [sortByRole, setSortByRole] = useState(false);

  const sortedUsers = () => {
    const rolePriority = ['superadmin', 'admin', 'moderator', 'user'];
    if (!sortByRole) {
      return users;
    }

    return [...users].sort((curr, prev) => rolePriority.indexOf(curr.adminRole) - rolePriority.indexOf(prev.adminRole));
  };

  return (
    <div className="table-container">
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Picture</th>
            <th>Email</th>
            <th>Name</th>
            <th className="cursor-pointer" onClick={() => setSortByRole(!sortByRole)}>
              <img src={arrowDown} alt="arrowDown" width="15" height="15" />
              Admin Role
            </th>
            <th>Team</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedUsers().map((user: UserWithTeamName) => (
            <tr key={user.id}>
              <td>
                <img
                  style={{ borderRadius: '50%', border: `1px solid ${user.teamId ? 'black' : 'red'}` }}
                  src={user.picture || avatarPlaceholder}
                  alt="avatar"
                  width="50"
                  height="50"
                />
              </td>
              <td>
                <strong>{user.email || '<no-email>'}</strong>
              </td>
              <td>{`${user.firstName} ${user.lastName}`}</td>
              <td>
                <strong style={{ color: adminColors[user.adminRole] }}>{user.adminRole}</strong>
              </td>
              <td style={{ color: user.email === user.teamName ? 'darkred' : 'black' }}>{user.teamName}</td>
              <td>
                <Link to={`/users/${user.id}`}>Edit</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
