import React from "react";
import { ResellerForm } from "../../components/ResellerForm";
import { Reseller } from "../../types/Reseller";

export const CreateReseller: React.FC = () => {
  const newReseller: Reseller = {
    id: 0,
    reseller: '',
    resellerInfo: '',
  }

  return (
    <>
      <h1 className="title">Create reseller</h1>

      <ResellerForm resellerItem={newReseller} isNewReseller={true} />
    </>
  );
};
