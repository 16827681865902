import React from 'react';
import { useParams } from 'react-router-dom';
import { UserForm } from '../../components/UserForm';

export const UserPage: React.FC = () => {
  const { userId } = useParams();
  const title = userId === 'new' ? 'Create user' : 'Edit user';

  return (
    <div className="">
      <h1 className="title">{title}</h1>

      <UserForm />
    </div>
  );
};
