import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';
import { Team } from '../../../../types/Team';
import { CurrencySelect } from '../../../ClientForm/CurrencySelect';

type Props = {
  team: Team;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
  balances: { amount: number; currency: string }[];
  onEditBalnce: () => void;
  onChange: () => void;
};

const currenciesSymbols = {
  EUR: '€',
  USD: '$',
  UAH: '₴',
};

export const PaymentsTab: React.FC<Props> = ({ team, handleChange, balances, onEditBalnce, onChange }) => {
  const { paymentType, currency } = team;
  return (
    <div className="box">
      <p className="title is-4 has-text-info">Charge & Payments information</p>

      <div className="block">
        <button className="button is-warning" onClick={onEditBalnce} type="button">
          Edit balance
        </button>
      </div>

      {balances.map((balance) => (
        <div key={balance.currency} className="tags has-addons">
          <span className="tag is-large is-dark">{balance.currency} balance:</span>
          <span
            className={classNames('tag is-large', {
              'is-success': balance.currency === currency,
            })}
          >
            {balance.amount.toFixed(2)} {currenciesSymbols[balance.currency as keyof typeof currenciesSymbols]}
          </span>
        </div>
      ))}

      <div className="field">
        <label className="label">Current main user's currency</label>
        <div className="control">
          <div className="select">
            <CurrencySelect name="currency" value={currency} onChange={handleChange} />
          </div>
        </div>
      </div>

      <div title="Dont edit this field if you dont have client's payment info" className="field">
        <label className="label">Payment type:</label>
        <div className="control">
          <div className="select">
            <select onChange={handleChange} name="paymentType" value={paymentType || ''}>
              <option value="">---</option>
              <option value="card">Card</option>
              <option value="invoices">Invoice</option>
            </select>
          </div>
        </div>
      </div>

      <button
        disabled={paymentType !== 'card'}
        className={`button ${paymentType === 'card' ? 'is-primary' : 'is-warning'}`}
        onClick={onChange}
        type="button"
      >
        Charge user
      </button>
    </div>
  );
};
