import React from 'react';
import { Tabs } from '../../UserForm';

type Props = {
  activeTab: Tabs;
  onChangeTab: (tab: Tabs) => void;
};

export const NavTabs: React.FC<Props> = ({ activeTab, onChangeTab }) => {
  return (
    <div className="tabs">
      <ul>
        <li onClick={() => onChangeTab(Tabs.UserInfoTab)} className={activeTab === Tabs.UserInfoTab ? 'is-active' : ''}>
          <a>User information</a>
        </li>
        <li onClick={() => onChangeTab(Tabs.TeamTab)} className={activeTab === Tabs.TeamTab ? 'is-active' : ''}>
          <a>User's Team</a>
        </li>
        <span style={{ display: 'none' }}>
          <li
            onClick={() => onChangeTab(Tabs.CompanyInfoTab)}
            className={activeTab === Tabs.CompanyInfoTab ? 'is-active' : ''}
          >
            <a>Company information</a>
          </li>
          <li
            onClick={() => onChangeTab(Tabs.PaymentsTab)}
            className={activeTab === Tabs.PaymentsTab ? 'is-active' : ''}
          >
            <a>Payments</a>
          </li>
          <li
            onClick={() => onChangeTab(Tabs.TransactionsTab)}
            className={activeTab === Tabs.TransactionsTab ? 'is-active' : ''}
          >
            <a>Transactions</a>
          </li>
          <li onClick={() => onChangeTab(Tabs.CardsTab)} className={activeTab === Tabs.CardsTab ? 'is-active' : ''}>
            <a>Cards</a>
          </li>
        </span>
      </ul>
    </div>
  );
};
