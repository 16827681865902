import React, { ChangeEventHandler, useState } from 'react';
import { api } from '../../../utils/fetch';
import { Loader } from '../../Loader';
import { CurrencySelect } from '../../ClientForm/CurrencySelect';
import { useParams } from 'react-router-dom';

type Props = {
  closeModal: () => void;
};

export const ChargeUserModal: React.FC<Props> = ({ closeModal }) => {
  const { userId } = useParams();

  const [chargeState, setChargeState] = useState<1 | 2>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [paymentData, setPaymentData] = useState({
    amount: 0,
    currency: 'EUR',
  });

  const chargeUserOnAPI = async () => {
    setIsLoading(true);
    try {
      await api.post('/payments/charge/' + userId, paymentData);

      setIsSuccess(true);
    } catch (e) {
      console.error(e);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal}></div>
      <div className="modal-content">
        <h1 className="title has-text-white">Charge user</h1>

        <div className="box">
          {chargeState === 1 ? (
            <>
              <label className="label">Currency and amount</label>

              <div className="field has-addons">
                <p className="control">
                  <span className="select">
                    <CurrencySelect name="currency" value={paymentData.currency} onChange={handleChange} />
                  </span>
                </p>

                <p className="control">
                  <input
                    onChange={(e) => {
                      setPaymentData((prevState) => ({
                        ...prevState,
                        amount: +e.target.value,
                      }));
                    }}
                    value={paymentData.amount}
                    className="input"
                    type="number"
                    placeholder="Amount of money"
                  />
                </p>
              </div>

              <button onClick={() => setChargeState(2)} className="button is-success">
                Accept
              </button>
            </>
          ) : (
            <>
              <label className="label">Are you sure?</label>
              <div className="notification is-link is-light">
                Do you want to charge user on{' '}
                <strong>
                  {paymentData.amount} {paymentData.currency}
                </strong>{' '}
                right now?
              </div>

              <div className="buttons">
                <button onClick={() => setChargeState(1)} className="button is-warning">
                  Back
                </button>
                <button onClick={() => chargeUserOnAPI()} className="button is-success">
                  {isLoading ? <Loader /> : 'Charge'}
                </button>
              </div>
            </>
          )}
        </div>

        {isSuccess && (
          <div className="box">
            <h2 className="title has-text-success">Success!</h2>
          </div>
        )}
        {isError && (
          <div className="box">
            <h2 className="title has-text-danger">Error!</h2>
          </div>
        )}
      </div>
    </div>
  );
};
