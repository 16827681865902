import React from 'react';
import { useParams } from 'react-router-dom';
import { ProductPriceForm } from '../../components/ProductPriceForm';

export const CreateProductPrice: React.FC = () => {
  const { productPriceId } = useParams();
  const title = productPriceId === 'new' ? 'Create Product price' : 'Edit Product price';

  return (
    <div>
      <h1 className="title">{title}</h1>

      <ProductPriceForm />
    </div>
  );
};
