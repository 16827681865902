import React from 'react';
import { LogEntry } from '../../../types/LogEntry';

type Props = {
  statuses: LogEntry[];
};

export const InvoiceStatuses: React.FC<Props> = ({ statuses }) => {
  return (
    <div className="box mt-4">
      {statuses.map((item) => {
        return item.level === 'error' ? (
          <div className="notification is-danger" key={item.domain}>
            <span className="icon has-text-warning">
              <i className="fas fa-exclamation-triangle"></i>
            </span>
            <strong>{item.domain}</strong> - <span>{item.message}</span>
          </div>
        ) : (
          <div className="notification is-primary is-light" key={item.domain}>
            <span className="icon has-text-success">
              <i className="fas fa-check-square"></i>
            </span>

            <strong>{item.domain}</strong>
          </div>
        );
      })}
    </div>
  );
};
