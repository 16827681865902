import React, { useState } from 'react';
import { Loader } from '../../components/Loader';
import { api } from '../../utils/fetch';

export const CreatePaymentLink: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pamentData, setPaymentData] = useState({
    amount: 0.0,
    currency: 'UAH',
    description: '',
    email: '',
  });
  const [paymentLink, setPaymentLink] = useState<null | string>(null);

  const createPaymentLinkOnAPI = async () => {
    setIsLoading(true);
    try {
      const res = await api.post<{ paymentLink: string }>('/payments/create-link', pamentData);

      setPaymentLink(res.paymentLink);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      <h1 className="title">Create Payment Link</h1>

      <div className="box">
        <label className="label">Currency and amount</label>
        <div className="field has-addons">
          <p className="control">
            <span className="select">
              <select
                onChange={(e) => {
                  setPaymentData((prevState) => ({
                    ...prevState,
                    currency: e.target.value,
                  }));
                }}
                value={pamentData.currency}
              >
                <option value="UAH">UAH</option>
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
              </select>
            </span>
          </p>
          <p className="control">
            <input
              onChange={(e) => {
                setPaymentData((prevState) => ({
                  ...prevState,
                  amount: +e.target.value,
                }));
              }}
              value={pamentData.amount}
              className="input"
              type="number"
              placeholder="Amount of money"
            />
          </p>
        </div>

        <div className="field">
          <textarea
            value={pamentData.description}
            onChange={(e) => {
              setPaymentData((prevState) => ({
                ...prevState,
                description: e.target.value,
              }));
            }}
            className="textarea"
            placeholder="Payment description"
          ></textarea>
        </div>

        <div className="field">
          <input
            value={pamentData.email}
            onChange={(e) => {
              setPaymentData((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            className="input"
            type="text"
            placeholder="Customer email"
          />
        </div>

        <button onClick={() => createPaymentLinkOnAPI()} className="button is-success">
          {isLoading ? <Loader /> : 'Create link'}
        </button>
      </div>

      {paymentLink && (
        <div className="box">
          <h2 className="title">Copy your payment link:</h2>
          <div className="notification is-primary">
            <a target="_blank" href={paymentLink}>
              {paymentLink}
            </a>
          </div>
          <div className="notification is-primary">
            <a href={paymentLink}>LINK</a>
          </div>
        </div>
      )}
    </>
  );
};
