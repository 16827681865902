import { useState } from 'react';
import { SuccessTag } from '../SuccessTag';
import './SuccessToast.css';

export const SuccessToast = () => {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <div onClick={() => setShow(false)} className="success-toast">
      <div className="success-toast__msg">
        <SuccessTag />
      </div>
    </div>
  );
};
