import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchTextField } from '../../components/SearchTextField';
import { UsersTable } from '../../components/UsersTable';
import { UserWithTeamName } from '../../types/User';
import { api } from '../../utils/fetch';

export const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<null | UserWithTeamName[]>(null);
  const [searchBy, setSearchBy] = useState('');

  const getUsersFromAPI = async () => {
    try {
      const res = await api.get<UserWithTeamName[]>('/users');

      setUsers(res);
    } catch (err) {
      console.error(err);
    }
  };

  const filteredUsers = users?.filter(
    (user) =>
      user.email.toLowerCase().match(searchBy.toLowerCase()) ||
      user.teamName.toLowerCase().match(searchBy.toLowerCase())
  );

  useEffect(() => {
    getUsersFromAPI();
  }, []);
  return (
    <div className="">
      <div className="is-flex is-justify-content-space-between">
        <h1 className="title">Users</h1>

        <Link to="/users/new" className="button is-primary">
          Create user
        </Link>
      </div>

      <SearchTextField
        searchBy={searchBy}
        onChangeSeachBy={(str) => setSearchBy(str)}
        onResetSearchBy={() => setSearchBy('')}
      />

      {filteredUsers && <UsersTable users={filteredUsers} />}
    </div>
  );
};
