import React from 'react';
import { Link } from 'react-router-dom';
import { Team } from '../../types/Team';
import avatarPlaceholder from '../../media/avatar-placeholder.svg';

type Props = {
  teams: Team[];
  onDeleteClick: (id: string) => void;
};

const resellerAbbreviation = (reseller: string): string => reseller.split('-')[1].slice(0, 3).toUpperCase();

export const TeamsTable: React.FC<Props> = ({ teams, onDeleteClick }) => {
  return (
    <div className="table-container">
      <table className="table is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Picture</th>
            <th>Name</th>
            <th>Domain</th>
            <th>Reseller</th>
            <th>Has Active Card</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team: Team) => (
            <tr key={team.id}>
              <td>
                <img
                  style={{ borderRadius: '50%' }}
                  src={team.picture || avatarPlaceholder}
                  alt="avatar"
                  width="50"
                  height="50"
                />
              </td>
              <td>{team.name}</td>
              <td>{team.domain || '<no-domains>'}</td>
              <td>{resellerAbbreviation(team.reseller)}</td>
              <td style={{ textAlign: 'center' }}>{team.activeCard ? '✅' : '❌'}</td>
              <td style={{ display: 'flex', flexDirection: 'column' }} className="has-text-centered">
                <Link to={`/teams/${team.id}`}>Edit</Link>
                <button className="button is-ghost has-text-danger" onClick={() => onDeleteClick(team.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
