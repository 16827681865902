import React from 'react';
import { User } from '../../../../types/User';

type Props = {
  users: User[] | undefined;
};

export const TeamUsersTab: React.FC<Props> = ({ users }) => {
  return (
    <div className="box">
      <p className="title is-4 has-text-info">Users in this Team:</p>

      {users ? (
        <table className="table is-striped">
          <tbody>
            {users.map((user, i) => (
              <tr>
                <th>{i + 1}</th>
                <td>
                  <strong>{user.email}</strong>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="title is-5 has-text-link">No users in this Team yet.</p>
      )}
    </div>
  );
};
