import React, { ChangeEventHandler } from 'react';
import { User } from '../../../../types/User';
import { countriesList } from '../../../../utils/countriesList';
import { TextInputField } from '../../../TextInputField';

type Props = {
  user: Omit<User, 'balance'>;
  handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
};

export const CompanyInfoTab: React.FC<Props> = ({ user, handleChange }) => {
  const {
    region,
    employees,
    isVatPayer,
    vatNumber,
    city,
    postalCode,
    companyName,
    companyNumber,
    address,
    fullAddress,
  } = user;

  return (
    <div className="box">
      <p className="title is-4 has-text-info">Company information</p>

      <label className="label">Is VAT payer and VAT number:</label>
      <div className="field has-addons">
        <p className="control">
          <span className="select">
            <select value={isVatPayer ? '+' : ''} name="isVatPayer" onChange={handleChange}>
              <option value="">NOT a VAT Payer</option>
              <option value="+">VAT Payer</option>
            </select>
          </span>
        </p>

        <p className="control">
          <input
            disabled={!isVatPayer}
            name="vatNumber"
            value={vatNumber || ''}
            onChange={handleChange}
            className="input"
            type="text"
            placeholder="VAT Number"
          />
        </p>
      </div>

      <div className="field">
        <label className="label">Employees:</label>
        <div className="control">
          <div className="select">
            <select onChange={handleChange} name="employees" value={employees || ''}>
              <option value="1-10">1-10</option>
              <option value="11-20">11-20</option>
              <option value="21-50">21-50</option>
              <option value="51-250">51-250</option>
              <option value="251-500">251-500</option>
              <option value="501-5000">501-5000</option>
              <option value="5000+">5000+</option>
            </select>
          </div>
        </div>
      </div>

      <TextInputField
        name="companyName"
        label="Company name:"
        value={companyName || ''}
        placeholder="Company name input"
        onChange={handleChange}
      />

      <TextInputField
        name="companyNumber"
        label="Company registration number/EDRPOU:"
        value={companyNumber || ''}
        placeholder="Company number input"
        onChange={handleChange}
      />

      <div className="field">
        <label className="label">Region:</label>
        <div className="control">
          <div className="select">
            <select onChange={handleChange} name="region" value={region || ''}>
              {countriesList.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <TextInputField name="city" label="City:" value={city || ''} placeholder="City input" onChange={handleChange} />

      <TextInputField
        name="address"
        label="Address:"
        value={address || ''}
        placeholder="Address input"
        onChange={handleChange}
      />

      <TextInputField
        name="postalCode"
        label="Postal code:"
        value={postalCode || ''}
        placeholder="Postal code input"
        onChange={handleChange}
      />

      {fullAddress && (
        <div className="field">
          <label className="label">Address from client (put it in right fields)</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Address from client"
              value={fullAddress}
              onChange={handleChange}
              name="fullAddress"
            ></textarea>
          </div>
        </div>
      )}
    </div>
  );
};
