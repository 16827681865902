import React from 'react';
import { Link } from 'react-router-dom';
import { CostsTable } from '../../components/CostsTable';

export const CostsPage: React.FC = () => {
  return (
    <>
      <div className="is-flex is-justify-content-space-between">
        <h1 className="title">Costs</h1>

        <Link to="/costs/new" className="button is-primary">
          Create cost
        </Link>
      </div>

      <CostsTable />
    </>
  );
};
