import React from 'react';

type Props = {
  title: string;
  buttons: React.ReactNode;
};

export const DeletePrompt: React.FC<Props> = ({ title, buttons }) => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          <h3 className="title">{title}</h3>

          <>{buttons}</>
        </div>
      </div>
      <button className="modal-close is-large" aria-label="close"></button>
    </div>
  );
};
