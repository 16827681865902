import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductsPrice } from '../../types/ProductPrice';
import { api } from '../../utils/fetch';
import { CurrencySelect } from '../ClientForm/CurrencySelect';
import { ErrorMessage } from '../ErrorMessage';
import { Loader } from '../Loader';
import { SuccessTag } from '../SuccessTag';

export const ProductPriceForm: React.FC = () => {
  const { productPriceId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentProductPrice, setCurrentProductPrice] = useState<ProductsPrice>({
    id: 0,
    product: '',
    clientPrice: 0,
    discountPercent: 0,
    currency: 'EUR',
  });

  useEffect(() => {
    const getProductPriceFromAPI = async (id: string) => {
      setIsLoading(true);

      try {
        const res = await api.get<ProductsPrice>('/products-prices/' + id);

        setCurrentProductPrice(res);
      } catch (e) {
        console.error(e);
        setIsError(true);
      }

      setIsLoading(false);
    };

    if (productPriceId && productPriceId !== 'new') {
      getProductPriceFromAPI(productPriceId);
    }
  }, []);

  const formSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSaving(true);

    try {
      if (productPriceId === 'new') {
        await api.post('/products-prices', currentProductPrice);
      } else {
        await api.put('/products-prices/' + productPriceId, currentProductPrice);
      }

      setIsSuccess(true);
    } catch (e) {
      console.error(e);
      setIsError(true);
    }
    setIsSaving(false);
  };

  const { product, clientPrice, currency, discountPercent } = currentProductPrice;

  return isLoading ? (
    <Loader />
  ) : (
    <form onSubmit={formSubmit} className="container">
      <div className="field">
        <label className="label">Product:</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="Product input"
            value={product}
            onChange={(e) => setCurrentProductPrice((prevState) => ({ ...prevState, product: e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Currency</label>
        <div className="control">
          <div className="select">
            <CurrencySelect
              name="currency"
              value={currency}
              onChange={(e) =>
                setCurrentProductPrice((prevState) => ({ ...prevState, currency: e.target.value as typeof currency }))
              }
            />
          </div>
        </div>
      </div>

      <div className="field">
        <label className="label">Client cost:</label>
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="Client cost input"
            value={clientPrice}
            onChange={(e) => setCurrentProductPrice((prevState) => ({ ...prevState, clientPrice: +e.target.value }))}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Discount percent:</label>
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="Discount percent input"
            value={discountPercent}
            onChange={(e) =>
              setCurrentProductPrice((prevState) => ({ ...prevState, discountPercent: +e.target.value }))
            }
          />
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link">{isSaving ? <Loader /> : 'Save cost'}</button>
          {isSuccess && <SuccessTag />}
        </div>

        {isError && <ErrorMessage message={null} />}
      </div>
    </form>
  );
};
