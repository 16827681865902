import React from 'react';
import { TransactionsTable } from '../../TransactionsTable';

type Props = {
  transactions?: {
    id: string;
    userId: string;
    cardId: string;
    amount: number;
    currency: string;
    sourceId: string;
    createdAt: string;
    description: string;
  }[];
};

export const TransactionsTab: React.FC<Props> = ({ transactions }) => {
  return (
    <div className="box">
      <p className="title is-4 has-text-info">User's transactions</p>

      {transactions && transactions.length ? (
        <TransactionsTable transactions={transactions} />
      ) : (
        <p className="title is-5 has-text-link">User dont have any transactions yet.</p>
      )}
    </div>
  );
};
